<template>
    <div id="medicine-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <form @submit.prevent="editMode ? update() : save()">
                        <div class="mb-3 row">
                            <div class="col-sm-4">
                                <input :class="{ 'is-invalid': errors['name'] }" placeholder="Service Name" required
                                       v-model="form.name" type="text"
                                       class="form-control mb-3" id="name">
                                <div v-if="errors['name']" class="invalid-feedback">
                                    {{ errors['name'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <select required :class="{ 'is-invalid': errors['is_web'] }" v-model="form.is_web"
                                        class="form-control mb-3">
                                    <option value="">Is Web</option>
                                    <option value="0">No</option>
                                    <option value="1">yes</option>
                                </select>
                                <div v-if="errors['is_web']" class="invalid-feedback">
                                    {{ errors['is_web'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <input :class="{ 'is-invalid': errors['price'] }" placeholder="Price" required
                                       v-model="form.price" type="text"
                                       class="form-control mb-3" id="price">
                                <div v-if="errors['price']" class="invalid-feedback">
                                    {{ errors['price'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <input type="submit" class="btn btn-primary w-100"
                                       :value="editMode ? 'Update' : 'Save'">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-5">
                    <form @submit.prevent="getList()">
                        <div class="mb-3 row">
                            <div class="col-sm-8 ms-auto">
                                <input v-model="table.search" type="text" class="form-control mb-3" id="name"
                                       placeholder="Searching...">
                            </div>
                            <div class="col-sm-4">
                                <input type="submit" class="btn btn-primary w-100" value="Searching">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Is Web</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="lists.length">
                            <tr v-for="(list, index) in lists">
                                <th scope="row">{{ table.pagination.from + index }}</th>
                                <td>{{ list.name }}</td>
                                <td>{{ list.price }}</td>
                                <td>{{ list.is_web == 1 ? 'Yes' : 'No' }}</td>
                                <td>
                                    <update-action type="modal" v-if="$root.$can('service', 'service-update')"
                                                   :id="list.id"/>
                                </td>
                                <td>
                                    <delete-action v-if="$root.$can('service', 'service-delete')" :id="list.id"/>
                                </td>
                            </tr>
                        </template>
                        <template v-if="!lists.length">
                            <tr>
                                <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

export default {
    name      : "ServiceIndex",
    components: {Pagination},
    data      : () => ({
        lists   : [],
        errors  : [],
        form    : {
            name  : '',
            price : '',
            is_web: "",
        },
        table   : {
            per_page  : 10,
            search    : '',
            pagination: {
                current_page: 1
            }
        },
        editMode: false
    }),
    mounted() {
        this.getList();
    },
    methods: {
        //-------CRUD------
        getList() {
            let params = {
                ...this.table,
                page: this.table.pagination.current_page,
            };
            ApiService.get('/services', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        save() {
            ApiService.post('/services', this.form).then((res) => {
                this.errors = []
                this.getList();
                NotificationService.success(res.data.message);
                this.formReset();
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/services/${id}`).then((res) => {
                this.form = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update() {
            ApiService.update(`/services/${this.form.id}`, this.form).then((res) => {
                this.errors = []
                NotificationService.success(res.data.message);
                this.formReset();
                let updatedItem        = res.data.data
                let foundIndex         = this.lists.findIndex(x => x.id == updatedItem.id);
                this.lists[foundIndex] = updatedItem;
                this.editMode          = false;
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/services/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                name  : '',
                price : '',
                is_web: "",
            }
        }
        //-------CRUD--------
    }
}
</script>
